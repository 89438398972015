// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #282828;
$primary_alt: #000000;

$secondary: #036BB6;
$secondary_alt: #0282DF;

$accent: #931714;
$accent_alt: #B81B17;

$light: #FFFFFF;
$light_alt: #EBEBEB;

$dark: #000000;
$dark_alt: #282828;

$foreground: #000000;
$foreground_alt: #282828;

$background: #FFFFFF;
$background_alt: #EBEBEB;

$page_background: #282828;
$page_background_alt: #000000;

$warning: #931714;
$warning_alt: #B81B17;

:root {
    --primary: $primary;
    --primary_alt: $primary_alt;
    --secondary: $secondary;
    --secondary_alt: $secondary_alt;
    --accent: $accent;
    --accent_alt: $accent_alt;
}

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "Lato", "Arial", "Helvetica", sans-serif;
$body-font: "Lato", "Arial", "Helvetica", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1300;
$column-gap: 30;
$content-padding: 25;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 3,
    content: 2,
    footer: 1,
);
