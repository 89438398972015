// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer_divider {
    & {
        height: auto;
        margin-bottom: 0;
        width: remify(4);
    }
}

.footer_logo {
    & {
        margin: 0;
    }
}
