/* ------------------------------------------------------------------------ * * Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors
\* ------------------------------------------------------------------------ */
:root {
  --primary: #282828;
  --primary_alt: #000000;
  --secondary: #036BB6;
  --secondary_alt: #0282DF;
  --accent: #931714;
  --accent_alt: #B81B17; }

/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins
\* ------------------------------------------------------------------------ */
._dark {
  color: #000000 !important; }

._light {
  color: #FFFFFF !important; }

._big {
  font-size: 1.5em !important; }

._small {
  font-size: 0.5em !important; }

._bold {
  font-weight: 700 !important; }

._italic {
  font-style: italic !important; }

._lowercase {
  text-transform: lower !important; }

._uppercase {
  text-transform: uppercase !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._nowrap {
  white-space: nowrap !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._nomargin {
  margin: 0 !important; }

._nopadding {
  padding: 0 !important; }

._notransform {
  transform: none !important; }

._notransition {
  transition: none !important; }

._block {
  display: block !important; }

._flex {
  -js-display: flex !important;
  display: flex !important; }

._visuallyhidden {
  background: #f1f1f1 !important;
  border-radius: 0 !important;
  box-shadow: 0em 0em 0.14286em 0.14286em rgba(0, 0, 0, 0.6) !important;
  color: #931714 !important;
  display: block !important;
  font-family: "Lato", "Arial", "Helvetica", sans-serif !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  height: auto !important;
  left: -999em !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  outline: none !important;
  padding: 1.07143em 1.64286em 1em !important;
  position: absolute;
  text-decoration: none !important;
  text-shadow: none !important;
  text-transform: none !important;
  top: -999em !important;
  white-space: nowrap;
  width: auto !important; }

._visuallyhidden:focus {
  left: 0.42857em !important;
  top: 0.42857em !important;
  z-index: 100000 !important; }

._primary {
  color: #282828 !important; }

._accent {
  color: #931714 !important; }

._warning {
  color: #931714 !important; }

._sticky {
  position: sticky !important;
  top: 0 !important; }

@media screen and (min-width: 601px) {
  @supports (position: -webkit-sticky) or (position: sticky) {
    .admin-bar ._sticky {
      top: 46px !important; } } }

@media screen and (min-width: 783px) {
  @supports (position: -webkit-sticky) or (position: sticky) {
    .admin-bar ._sticky {
      top: 32px !important; } } }

/* visibility classes */
@media (pointer: fine) {
  ._touch {
    display: none !important; } }

@supports not (pointer: fine) {
  ._touch {
    display: none !important; } }

@media (pointer: course) {
  ._mouse {
    display: none !important; } }

@media print {
  ._noprint {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  ._tablet:not(._phone),
  ._notebook:not(._phone),
  ._desktop:not(._phone) {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  ._phone:not(._tablet),
  ._notebook:not(._tablet),
  ._desktop:not(._tablet) {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 79.9375em) {
  ._phone:not(._notebook),
  ._tablet:not(._notebook),
  ._desktop:not(._notebook) {
    display: none !important; } }

@media screen and (min-width: 80em) {
  ._phone:not(._desktop),
  ._tablet:not(._desktop),
  ._notebook:not(._desktop) {
    display: none !important; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Catalog List
\* ------------------------------------------------------------------------ */
.catalog-list_divider {
  margin: 10em 1.25em;
  width: 5.625em; }

.catalog-list_container {
  flex: 1 1 auto;
  height: 46.875em;
  margin-bottom: 1.5625em;
  min-height: 0;
  overflow: auto;
  padding: 0.75em 0.625em; }

.catalog-list_container.-short {
  height: 36.875em; }

.catalog-list_container:last-child {
  margin-bottom: 0; }

.catalog-list {
  flex: 1 1 auto;
  font-size: 1.125em; }

/* stylelint-disable selector-class-pattern */
.ms-container {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 138 109'%3E%3Cpath d='M93.5,109L138,78.3L93.5,49.7v8.9H51.8v41.5h41.7V109z M44.5,0L0,30.7l44.5,28.6v-8.9h41.7V8.9 H44.5V0z' fill='%23D0DBE1' /%3E%3C/svg%3E%0A") center top 10em/4.3125em 3.375em no-repeat;
  -js-display: flex;
  display: flex; }

.ms-selectable,
.ms-selection {
  background: none;
  flex: 1 1 100%;
  padding-bottom: 0; }

.ms-selectable,
.ms-selection {
  -js-display: flex;
  display: flex;
  flex-direction: column; }

.ms-selectable {
  margin-right: 4.0625em; }

.ms-selection {
  margin-left: 4.0625em; }

/* stylelint-enable selector-class-pattern */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
.header_logo .logo_image {
  height: 5em; }

/* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
.footer_divider {
  height: auto;
  margin-bottom: 0;
  width: 4px;
  width: 0.25rem; }

.footer_logo {
  margin: 0; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
