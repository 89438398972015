// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Catalog List
\* ------------------------------------------------------------------------ */

.catalog-list_divider {
    & {
        margin: remify(160 20, 16);
        width: remify(90, 16);
    }
}

.catalog-list_container {
    & {
        flex: 1 1 auto;
        height: remify(750, 16);
        margin-bottom: remify(25, 16);
        min-height: 0;
        overflow: auto;
        padding: remify(12 10, 16);
    }

    &.-short {
        height: remify(590, 16);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.catalog-list {
    & {
        flex: 1 1 auto;
        font-size: remify(18, 16);
    }
}

// jQuery multiselect

/* stylelint-disable selector-class-pattern */

.ms-container {
    & {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 138 109'%3E%3Cpath d='M93.5,109L138,78.3L93.5,49.7v8.9H51.8v41.5h41.7V109z M44.5,0L0,30.7l44.5,28.6v-8.9h41.7V8.9 H44.5V0z' fill='%23D0DBE1' /%3E%3C/svg%3E%0A")  center top remify(160, 16) / remify(69 54, 16) no-repeat;
        display: flex;
    }
}

.ms-selectable,
.ms-selection {
    & {
        background: none;
        flex: 1 1 100%;
        padding-bottom: 0;
    }
}

.ms-selectable,
.ms-selection {
    & {
        display: flex;
        flex-direction: column;
    }
}

.ms-selectable {
    & {
        margin-right: remify(65, 16);
    }
}

.ms-selection {
    & {
        margin-left: remify(65, 16);
    }
}

/* stylelint-enable selector-class-pattern */
