// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */

@import "../module/catalog-list/_catalog-list_xs";

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */

@import "../layout/header/_header_xs";
@import "../layout/footer/_footer_xs";
